export const COMPLETED_STATUS = "COMPLETED"
export const CANCELLED_STATUS = "CANCELLED"
export const PENDING_STATUS = "PENDING"
export const PART_ORDERED = "PART ORDERED";
export const PART_INTRANSIT = "PART IN-TRANSIT";
export const PART_CANCELLED = "PART CANCELLED";
export const PART_DELIVERED = "PART DELIVERED TO SERVICE CENTER";
export const PART_NOT_RECEIVED = "PART NOT RECEIVED";
export const PART_ESTIMATE_TO_BE_APPROVED = "PART ESTIMATE TO BE APPROVED";
export const ESTIMATE_REJECTED = "ESTIMATE REJECTED"
export const DFECTIVE_PARTS_TO_BE_SHIPPED = "DEFECTIVE PARTS TO BE SHIPPED";
export const OK_PARTS_TO_BE_SHIPPED = "OK PARTS TO BE SHIPPED";
export const BOOKING_COMPLETED_UNDER_REVIEW = "BOOKING COMPLETED UNDER REVIEW";
export const BOOKING_REJECTED_UNDER_REVIEW = "BOOKING REJECTED UNDER REVIEW";


export const EMPLOYEE_TYPE =  1;
export const PARTNER_TYPE = 2;
export const SF_TYPE = 3;