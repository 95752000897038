import React, { useEffect, useState } from "react";
import Select from "react-select";
import "../../asset/css/suggestion.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import { useNavigate, useParams } from "react-router-dom";
import { UploadFileToS3 } from "../../utils/S3File";
import { LABEL_JOB } from "../../constant/label_constant";
import MainBody from "../../component/MainBody";
import httpRequest from "../../utils/Http";
import { getdropdownList } from "../../utils/HelperFunction";
import { useSelector } from "react-redux";
import { CANCELLED_STATUS } from "../../constant/status_constant";

const UpdateBooking = () => {
  const navigate = useNavigate();
  const [isSubmit, setSubmit] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [modelNumberList, setModelNumberList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [capacityList, setCapacityList] = useState([]);
  const [suggestionText, setSuggestionText] = useState("");
  const [purchaseDate, setPurchaseDate] = useState();
  const [serviceCategory, setServiceCategory] = useState([]);
  const [serviceChecked, setServiceChecked] = useState([]);
  const [totalServiceCharge, setTotalServiceCharge] = useState(0);
  const [bidCalltypeList, setBidCalltypeList] = useState([]);
  const [bookingUpcountry, setBookingUpcountry] = useState([]);
  const [typeGroupoptions, setTypeGroup] = useState([]);
  const [modelFreeze, setModelFreeze] = useState(false)
  const { bid, isPartOrder, spare_id } = useParams();
  let loggedInUser = useSelector((state) => state.authReducer.currentUser);

  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    (async () => {
      if (bid) {
        try {
          const id = atob(bid);
          const data = await httpRequest(
            "getbookingdetails?ishistory=0&id=" + id,
            {},
            "GET"
          );

          if (data.status == true) {
            if (
              !data.bookingData.closed_date
            ) {
              // if(!data.bookingData.sc_closed_date && (Number(loggedInUser.entity_type) != 1) ){
              //   toast.error(
              //     `${LABEL_JOB} already closed. You can not allow to update booking`
              //   );
              //   navigate('/pendingbooking')
              // }
              const sf_purchase_date = data.userApplianceDetails
                .sf_purchase_date
                ? new Date(data.userApplianceDetails.sf_purchase_date)
                : "";

              setFormData({
                ...formData,
                user_id: data.bookingData.user_id,
                booking_id: data.bookingData.booking_id,
                partner_id: data.bookingData.partner_id,
                calltype: {
                    label: data.bookingData.calltype_group.type,
                    value: data.bookingData.calltype_group.id,
                },
                appliance_id: data.bookingData.appliance_id,
                model_number: data.userApplianceDetails.model_number,
                model_id: data.userApplianceDetails.model_id,
                brand_id: data.userApplianceDetails.brand_id,
                category_id: data.userApplianceDetails.category_id,
                capacity_id: data.userApplianceDetails.capacity_id,
                sf_serial_number: data.userApplianceDetails.sf_serial_number,
                sf_purchase_date: sf_purchase_date,
                sf_invoice_id: data.bookingData.sf_invoice_id,
                userappliance_id: data.bookingData.userappliance_id,
                bid: data.bookingData.id,
                sf_serial_number_file: data.userApplianceDetails.sf_serial_number_file,
                sf_invoice_file: data.userApplianceDetails.invoice_file,
              });

              setBidCalltypeList(data.bookingCharges);
              setBookingUpcountry(data.bookingUpcountry);

              setPurchaseDate(sf_purchase_date);
              let modelFreeze = false;
              if((Number(loggedInUser.role_id) != 5 && Number(loggedInUser.role_id) != 6) ){
                if(data.spareParts.length > 0){
                  data.spareParts.forEach(item => {
                    if(item.status !== CANCELLED_STATUS){
                      modelFreeze = true;
                    }
                  })
                }
              }

              setModelFreeze(modelFreeze)

            } else {
              toast.error(
                `${LABEL_JOB} already closed. You can not allow to update booking`
              );
              navigate("/pendingbooking");
            }
          } else {
            console.log(".....");
            toast.error(`${LABEL_JOB} Not Found`);
            navigate("/404");
          }
        } catch (error) {
          console.log(".....");
          navigate("/404");
        }
      }
    })();
  }, [bid]);

  useEffect(() => {
    (async () => {
      //console.log('.......hi.......')
      if (formData.partner_id && formData.appliance_id) {
        //   console.log('IN')
        if (suggestionText.length > 3) {
          //     console.log('IN1')
          const listdata = await httpRequest(
            `getmodellist?partner_id=${formData.partner_id}&appliance_id=${formData.appliance_id}&search=${suggestionText}`,
            {},
            "GET"
          );
          if (listdata.status === true && listdata.data) {
            setModelNumberList(listdata.data["rows"]);
          } else {
            setModelNumberList([]);
            const { model_id, ...newFormData } = formData;
            setFormData(newFormData);
          }
        } else if (!suggestionText) {
          const { model_number, model_id, ...newFormData } = formData;
          setFormData(newFormData);
        }
      } else if (suggestionText) {
        setFormErrors({ model_number: "Please select appliance first" });
        const { model_number, model_id, ...newFormData } = formData;
        setFormData(newFormData);
      }
    })();
    //eslint-disable-next-line
  }, [suggestionText]);

  useEffect(() => {
    (async () => {
      getdropdownList(setTypeGroup, "getcalltypegrouplist" + "?isactive=1");
    })();
    //eslint-disable-next-line
  }, []);

  const suggestionHandler = (model) => {
    // console.log(model);
    setFormData({
      ...formData,
      model_number: model.model_number,
      model_id: model.id,
    });
    setModelNumberList([]);
  };

  useEffect(() => {
    if (!bid) {
      setBrandList([]);
      setCategoryList([]);
      setCapacityList([]);
      setFormData({
        ...formData,
        brand_id: null,
        category_id: null,
        capacity_id: null,
      });
    }

    if (formData.model_id) {
      (async () => {
        const brand = await httpRequest(
          `getbrandlistmapping?partner_id=${formData.partner_id}&appliance_id=${formData.appliance_id}&model_id=${formData.model_id}`,
          {},
          "GET"
        );

        if (brand.status === true && brand.data) {
          if (!bid) {
            const { category_id, capacity_id, ...rest } = formData;
            setFormData(rest);
          }
          setBrandList(brand.data);
          if (brand.data.length == 1) {
            setFormData({ ...formData, brand_id: brand.data[0].value });
          }
        }
      })();
    }
    //eslint-disable-next-line
  }, [formData.model_id]);

  useEffect(() => {
    setCapacityList([]);
    //setFormData({ ...formData, category_id: null, capacity_id: null });
    if (formData.brand_id) {
      (async () => {
        const category = await httpRequest(
          `getcategorylistmapping`,
          {
            partner_id: formData.partner_id,
            appliance_id: formData.appliance_id,
            brand: [{ value: formData.brand_id }],
            model_id: formData.model_id,
          },
          "POST"
        );

        if (category.status === true && category.data) {
          setCategoryList(category.data);
          if (category.data.length == 1) {
            setFormData({ ...formData, category_id: category.data[0].value });
          }
        }
      })();
    }
    //eslint-disable-next-line
  }, [formData.brand_id]);

  useEffect(() => {
    setCapacityList([]);

    if (formData.category_id) {
      (async () => {
        const capacity = await httpRequest(
          `getcapacitylistmapping`,
          {
            partner_id: formData.partner_id,
            appliance_id: formData.appliance_id,
            brand: [{ value: formData.brand_id }],
            category: [{ value: formData.category_id }],
            model_id: formData.model_id,
          },
          "POST"
        );

        if (capacity.status === true && capacity.data) {
          setCapacityList(capacity.data);
          if (capacity.data.length == 1) {
            setFormData({ ...formData, capacity_id: capacity.data[0].value });
          }
        }
      })();
    }
    //eslint-disable-next-line
  }, [formData.category_id]);

  useEffect(() => {
    (async () => {
      //setServiceCategory([]);

      if (
        formData.partner_id &&
        formData.appliance_id &&
        formData.brand_id &&
        formData.category_id != null &&
        formData.capacity_id
      ) {
        let postData = {
          partner_id: formData.partner_id,
          appliance_id: formData.appliance_id,
          brand: [{ value: formData.brand_id }],
          category: [{ value: formData.category_id }],
          capacity: [{ value: formData.capacity_id }],
          calltypeGroupID: formData.calltype,
        };
        const data = await httpRequest("filterchargeslist", postData, "POST");

        if (data.status == true && data.data) {
          setServiceCategory(data.data);
        } else {
          setServiceCategory([]);
        }
      }
    })();
  }, [
    formData.partner_id,
    formData.appliance_id,
    formData.calltype,
    formData.brand_id,
    formData.category_id,
    formData.capacity_id,
  ]);

  useEffect(() => {
    if (serviceCategory.length > 0 && bidCalltypeList.length > 0) {
      const initialChecked = serviceCategory.filter((element) => {
        return bidCalltypeList.some((selected) => {
          return (
            String(selected.service_category) === String(element.call_type_id)
          ); // Ensure both are strings
        });
      });

      setServiceChecked(initialChecked);

      setFormData({ ...formData, service_category: initialChecked });

      let upcountry = 0;
      if (
        bookingUpcountry.length > 0 &&
        bookingUpcountry[0].upcountry_paid_by == 2
      ) {
        upcountry =
          bookingUpcountry[0].upcountry_distance *
          bookingUpcountry[0].upcountry_sf_rate;
      }

      let initialTotalCharge = initialChecked.reduce((prev, current) => {
        return prev + parseFloat(current.customer_payable);
      }, 0);

      initialTotalCharge = initialTotalCharge + parseFloat(upcountry);

      setTotalServiceCharge(initialTotalCharge);
    }
  }, [serviceCategory, bidCalltypeList, bookingUpcountry]);

  const handleChargesCheckbox = (event, value) => {
    let updatedList = [...serviceChecked];
    if (event.target.checked) {
      updatedList = [...serviceChecked, value];
    } else {
      updatedList = updatedList.filter((item) => item.id !== value.id);
    }

    setServiceChecked(updatedList);
    setFormData({ ...formData, service_category: updatedList });

    let totalCharge = updatedList.reduce((prev, current) => {
      return prev + parseFloat(current.customer_payable);
    }, 0);

    let upcountry = 0;
    if (
      bookingUpcountry.length > 0 &&
      bookingUpcountry[0].upcountry_paid_by == 2
    ) {
      upcountry =
        bookingUpcountry[0].upcountry_distance *
        bookingUpcountry[0].upcountry_sf_rate;
    }

    totalCharge = totalCharge + parseFloat(upcountry);

    setTotalServiceCharge(totalCharge);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  const validateFrom = (value) => {
    const errors = {};

    const requiredFields = [
      { field: "model_number", message: "Model Number is Required" },
      { field: "category_id", message: "Category is Required" },
      { field: "capacity_id", message: "Capacity is Required" },
      { field: "brand_id", message: "Brand is Required" },
      { field: "service_category", message: "Service Category is Required" },
      { field: "remarks", message: "Remarks is Required" },
    ];

    requiredFields.forEach(({ field, message }) => {
      if (!value[field]) {
        errors[field] = message;
      }
    });

    if (!value.service_category) {
      errors.service_category = "Service Category is Manadatory";
    } else if (value.service_category.length === 0) {
      errors.service_category = "Service Category is Manadatory";
    } else {
      let invoicePod = 0;
      let serialNumberPod = 0;
      value.service_category.map((item) => {
        if (Number(item.invoice_pod) === 1) {
          invoicePod = 1;
        }
        if (Number(item.serial_number_pod) === 1) {
          serialNumberPod = 1;
        }
      });

      if (invoicePod === 1) {
        if (!value.sf_invoice_id) {
          errors.sf_invoice_id = "Invoice Number is Manadatory";
        }

        if (!value.invoice_file && !value.sf_invoice_file) {
          errors.invoice_file = "Invoice File is Manadatory";
        }

        if (!value.sf_purchase_date) {
          errors.sf_purchase_date = "Purchase Date is Manadatory";
        }
      }

      if (serialNumberPod === 1) {
        if (!value.sf_serial_number) {
          errors.sf_serial_number = "Serial Number is Manadatory";
        }

        if (!value.serial_number_file && !value.sf_serial_number_file) {
          errors.serial_number_file = "Serial Number file is Manadatory";
        }
      }
    }

    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        let postData = formData;
        if (formData.invoice_file) {
          const invoice_file = await UploadFileToS3(
            "docFile",
            formData.invoice_file
          );
          postData.sf_invoice_file = invoice_file;
        }

        if (formData.warranty_file) {
          const warranty_file = await UploadFileToS3(
            "docFile",
            formData.warranty_file
          );
          postData.sf_warranty_file = warranty_file;
        }

        if (formData.serial_number_file) {
          const serial_number_file = await UploadFileToS3(
            "docFile",
            formData.serial_number_file
          );
          postData.sf_serial_number_file = serial_number_file;
        }

     //   document.getElementById("addbasicdetails").reset();

        console.log(formData);
        const data = await httpRequest("processsfupdate", postData, "POST");

        setSubmit(false);
        if (data.status === true && data.message) {
          toast.success(data.message);
          if (Number(atob(isPartOrder)) === 1) {
            if(spare_id){
              navigate(`/requestpartorder/${bid}/${spare_id}`);
            } else {
              navigate(`/requestpartorder/${bid}`);
            }
            
          } else if (Number(atob(isPartOrder)) === 2) {
            navigate(`/completebooking/${bid}`);
          }
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  return (
    <div>
      <MainBody
        breadcrumbTitle={
          formData.booking_id ? `Update ${LABEL_JOB}` : "Create Booking"
        }
        breadcrumbSubTitle={formData.booking_id ? formData.booking_id : ""}
        navButton={""}
        title={""}
        component={
          <>
            <section className="content">
              <div className="container-fluid">
                <form id="addbasicdetails" onSubmit={onSubmitHandler}>
                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">Appliance Details</h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="row">
                      <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Call Type *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.calltype}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                defaultValue={formData.calltype}
                                value={formData.calltype}
                                name="calltypeggroup_id"
                                isDisabled={modelFreeze}
                                placeholder="Select Call Type"
                                options={typeGroupoptions}
                                onChange={(val) => {
                                  setFormData({ ...formData, calltype: val });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Model Number *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.model_number}
                              </span>
                            </label>
                            <div className="input-container">
                              <input
                                type="text"
                                value={formData.model_number}
                                readOnly={modelFreeze}
                                className={
                                  !formErrors.model_number
                                    ? "form-control form-icon-input "
                                    : "is-invalid form-icon-input form-control "
                                }
                                name="model_number"
                                placeholder="Enter Model Number"
                                id="model_number"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    model_number: e.target.value,
                                    model_id: null,
                                  });

                                  setSuggestionText(e.target.value);
                                  if (!e.target.value) {
                                    setModelNumberList([]);
                                  }
                                }}
                                onBlur={(e) => {
                                  setTimeout(() => {
                                    setModelNumberList([]);
                                    //getCategoryList();
                                  }, 100);
                                }}
                              />
                              {modelNumberList &&
                                modelNumberList.length > 0 && (
                                  <div className="suggestion-dialog scrollbar-overlay">
                                    {modelNumberList &&
                                      modelNumberList.map((suggestion, i) => {
                                        return (
                                          <div
                                            key={i}
                                            className="col-md-12 justify-content-md-center suggestion-model"
                                            onClick={() => {
                                              suggestionHandler(suggestion);
                                            }}
                                          >
                                            {suggestion.model_number}
                                          </div>
                                        );
                                      })}
                                  </div>
                                )}
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Brand *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.brand_id}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                name="brand_id"
                                placeholder="Select Brand"
                                value={
                                  brandList.find(
                                    (option) =>
                                      option.value === formData.brand_id
                                  ) || null
                                }
                                options={brandList}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    brand_id: val.value,
                                    capacity_id: null,
                                    category_id: null,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Category *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.category_id}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                name="category_id"
                                placeholder="Select Category"
                                value={
                                  categoryList.find(
                                    (option) =>
                                      option.value === formData.category_id
                                  ) || null
                                }
                                options={categoryList}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    category_id: val.value,
                                    capacity_id: null,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Capacity *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.capacity_id}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <Select
                                name="capacity_id"
                                placeholder="Select Capacity"
                                value={
                                  capacityList.find(
                                    (option) =>
                                      option.value === formData.capacity_id
                                  ) || null
                                }
                                options={capacityList}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    capacity_id: val.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Serial Number{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.sf_serial_number}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="sf_serial_number"
                              placeholder="Enter Serial Number"
                              id="serial_number"
                              defaultValue={formData.sf_serial_number}
                              value={formData.sf_serial_number}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Purchase Date{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.sf_purchase_date}
                              </span>
                            </label>

                            <DatePicker
                              maxDate={new Date()}
                              selected={purchaseDate}
                              placeholderText="Select Purchase Date"
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              onChange={(date) => {
                                setFormData({
                                  ...formData,
                                  sf_purchase_date: date,
                                });
                                setPurchaseDate(date);
                              }}
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                            {"<"}
                                                        </button> */}
                                  <select
                                    style={{
                                      marginRight: 3,
                                    }}
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <select
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                            {">"}
                                                        </button> */}
                                </div>
                              )}
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Invoice Number *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.sf_invoice_id}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <input
                                name="sf_invoice_id"
                                value={formData.sf_invoice_id}
                                id="sf_invoice_id"
                                placeholder="Enter Number"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                                onBlur={(e) => {
                                  // checkDuplicateOrder(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Remarks *{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.remarks}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <input
                                name="remarks"
                                id="remarks"
                                value={formData.remarks}
                                placeholder="Enter remarks"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    [e.target.name]: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">Service Category</h3>
                      <div
                        className="card-tools"
                        style={{ color: "red", marginRight: "40px" }}
                      >
                        <b>{formErrors.service_category}</b>
                      </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="row">
                        <table className="table table-bordered  table-hover table-striped data dataTable no-footer ">
                          <thead>
                            <tr>
                              <th>SNo.</th>
                              <th>Service Category</th>
                              <th>Customer Charges</th>
                              <th>Select Services</th>
                            </tr>
                          </thead>
                          <tbody>
                            {serviceCategory.map((element, index) => {
                              const isChecked = serviceChecked.some(
                                (item) => item.id === element.id
                              );
                              return (
                                <tr
                                  key={index}
                                  id={"call_" + index}
                                  className="text-center"
                                >
                                  <td>{index + 1}</td>
                                  <td>{element.call_type}</td>
                                  <td>{element.customer_payable}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="form-control charges_checkbox text-capitalize"
                                      name="charges_checkbox"
                                      id={"charges_checkbox" + index}
                                      value={element.id}
                                      checked={isChecked}
                                      onChange={(e) =>
                                        handleChargesCheckbox(e, element)
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                            {bookingUpcountry.length > 0 &&
                              bookingUpcountry[0].is_upcountry == 1 && (
                                <tr
                                  key={"upcountry"}
                                  id={"call_upcountry"}
                                  className="text-center"
                                >
                                  <td>{serviceCategory.length + 1}</td>
                                  <td>Upcountry</td>
                                  <td>
                                    {bookingUpcountry[0].upcountry_paid_by == 2
                                      ? bookingUpcountry[0].upcountry_sf_rate *
                                        bookingUpcountry[0].upcountry_distance
                                      : 0}
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="form-control charges_checkbox text-capitalize"
                                      name="charges_checkbox"
                                      id={"charges_checkbox_upcountry"}
                                      value={"upcountry"}
                                      checked={true}
                                    />
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Total Customer Charge {" Incl GST"}</label>
                            <input
                              type="text"
                              className="form-control text-capitalize"
                              name="total_service_charge"
                              placeholder="Total Service Charge"
                              id="total_service_charge"
                              value={totalServiceCharge}
                              readOnly="readonly"
                            />
                          </div>
                          {/* /.form-group */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-default">
                    <div className="card-header">
                      <h3 className="card-title">File Upload</h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Invoice File{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.invoice_file}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <input
                                name="invoice_file"
                                id="invoice_file"
                                type="file"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    invoice_file: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Warranty File{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.warranty_file}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <input
                                name="warranty_file"
                                id="warranty_file"
                                type="file"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    warranty_file: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Serial Number File{" "}
                              <span style={{ color: "red" }}>
                                {formErrors.serial_number_file}
                              </span>
                            </label>
                            <div className="select2-purple">
                              <input
                                name="serial_number_file"
                                id="serial_number_file"
                                type="file"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    serial_number_file: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* /.form-group */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card-footer text-center"
                    style={{ marginBottom: "60px" }}
                  >
                    <button
                      type="submit"
                      id="submit"
                      className="btn btn-primary col-md-6"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </>
        }
      />
    </div>
  );
};

export default UpdateBooking;
