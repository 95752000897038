import React, { useState, useEffect } from "react";
import {
  isValidAadhaarNumber,
  isValidGstNumber,
  isValidPanNumber,
  openSignUrl,
} from "../../../utils/HelperFunction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UploadFileToS3 } from "../../../utils/S3File";
import httpRequest from "../../../utils/Http";
import { Link } from "react-router-dom";
import { ONLY_RM_ASM_ACTION_PERMISSION } from "../../../constant/action_permission";

export default function UpdateSfDocuments({ sfdetails, SetSfDetails, roleId }) {
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setSubmit] = useState(false);

  let fd = {
    sf_id: sfdetails.id,
    pan_number: sfdetails.pan_number,
    aadhar_number: sfdetails.aadhar_number,
  };

  if (sfdetails.gst_details !== null) {
    fd = {
      ...fd,
      gst_number: sfdetails.gst_details.gst_number,
      gst_id: sfdetails.gst_details.id,
    };
  }

  const [formData, setFormData] = useState(fd);

  const validateFrom = (value) => {
    const errors = {};
    //console.log(value);
    if (!value.pan_number) {
      errors.pan_number = "Pan Number is Required";
    } else {
      if (!value.pan_file && value.pan_number !== sfdetails.pan_number) {
        errors.pan_file = "Pan File is Required (Only Image & PDF file)";
      }

      if (!isValidPanNumber(value.pan_number)) {
        errors.pan_number = "Pan Number is not valid";
      }
    }

    if(value.gst_number) {
      if (
        sfdetails.gst_details !== null &&
        !value.gst_file &&
        value.gst_number !== sfdetails.gst_details.gst_number
      ) {
        errors.gst_file = "GST File is Required (Only Image & PDF file)";
      } else if (!value.gst_file && sfdetails.gst_details === null) {
        errors.gst_file = "GST File is Required (Only Image & PDF file)";
      }

      if (!isValidGstNumber(value.gst_number)) {
        errors.gst_number = "GST Number is not valid";
      }

      // const state = Number(value.gst_number.slice(0, 2));
      // console.log(sfdetails);
      // if (state !== Number(sfdetails.state.state_code)) {
      //   errors.gst_number =
      //     "GST Number is not valid. State code is not matching";
      // }
    }

    if (value.aadhar_number) {
      if (!value.aadhar_file && value.aadhar_number !== sfdetails.aadhar_number) {
        errors.aadhar_file = "Aadhar File is Required (Only Image & PDF file)";
      }

      // if (!isValidAadhaarNumber(value.aadhar_number)) {
      //   errors.aadhar_number = "Aadhar Number is not valid";
      // }
    }

    if (
      sfdetails.gst_details !== null &&
      value.pan_number == sfdetails.pan_number &&
      value.aadhar_number == sfdetails.aadhar_number &&
      value.gst_number == sfdetails.gst_details.gst_number &&
      !value.pan_file &&
      !value.gst_file &&
      !value.aadhar_file
    ) {
      errors.mandatory = "There is no change in the existing data.";
    }
    console.log(errors);
    return errors;
  };
  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        let formData1 = {};
        formData1.sf_id = formData.sf_id;
        if (formData.gst_id) {
          formData1.gst_id = formData.gst_id;
        }
        if (formData.pan_number) {
          formData1.pan_number = formData.pan_number;
        }
         console.log(formData.pan_file)
        if (formData.pan_file) {
          
          const panfile = await UploadFileToS3("docFile", formData.pan_file);
          formData1.pan_file = panfile;
        } else {
          formData1.pan_file = sfdetails.pan_file;
        }

        if(formData.aadhar_number){
          formData1.aadhar_number = formData.aadhar_number;

          console.log(formData.aadhar_file)
          if (formData.aadhar_file) {
            const aadharfile = await UploadFileToS3("docFile", formData.aadhar_file);
            formData1.aadhar_file = aadharfile;
          } else {
            formData1.aadhar_file = sfdetails.aadhar_file;
          }

        }

        if (formData.gst_number) {
          formData1.gst_number = formData.gst_number;
          formData1.pincode = sfdetails.pincode;
          formData1.city = sfdetails.city;
          formData1.state_id = sfdetails.state_id;

          if (formData.gst_file) {
            const gstfile = await UploadFileToS3("docFile", formData.gst_file);
            formData1.gst_file = gstfile;
          }

          if (!formData.gst_file) {
            formData1.gst_file = sfdetails.gst_details?.gst_file || "";
          }
        }

        document.getElementById("updateSFDocuments").reset();

        console.log(formData);
        const data = await httpRequest("updatesfdocuments", formData1, "POST");

        setSubmit(false);
        if (data.status === true && data.message) {
          SetSfDetails(data.data);
          toast.success(data.message);
          // navigate("/sfdetails/" + data.data["id"] + "?tab=documents");
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    //console.log(formData);
    setSubmit(false);
    setFormErrors(validateFrom(formData));
    setSubmit(true);
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <form
            id="updateSFDocuments"
            onSubmit={onSubmitHandler}
            encType="multipart/form-data"
          >
            <div className="card card-default">
              <div className="card-header">
                <h3 className="card-title">Document Details</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Gst Numnber{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.gst_number}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-uppercase"
                        id="gst_number"
                        placeholder="Enter Gst Number"
                        defaultValue={formData.gst_number}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            gst_number: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Gst File{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.gst_file}
                        </span>
                      </label>
                      <div className="row">
                        <input
                          type="file"
                          className="form-control col-md-10"
                          id="gst_file"
                          onChange={(e) => {
                            var files = e.target.files;
                            if (files && files[0]) {
                              var file_name = e.target.files[0]["name"];
                              const regex = new RegExp(
                                "(.*?)(pdf|png|jpg|jpeg)$"
                              );
                              if (!regex.test(file_name.toLowerCase())) {
                                setFormErrors({
                                  gst_file:
                                    "GST File is Required (Only PDF & Image file)",
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  gst_file: e.target.files[0],
                                });
                              }
                            } else {
                              setFormErrors({
                                ...formErrors,
                                gst_file:
                                  "GST File is Required (Only PDF & Image) file allow",
                              });
                            }
                          }}
                        />
                        <div className="col-md-2">
                          {sfdetails.gst_details?.gst_file && (
                            <Link
                              to={"javascript:void(0)"}
                              className="btn-link col-sm-2 text-primary"
                              onClick={() =>
                                openSignUrl(sfdetails.gst_details.gst_file)
                              }
                            >
                              View
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Pan Number{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.pan_number}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-uppercase"
                        id="pan_number"
                        placeholder="Enter PAN Number"
                        defaultValue={formData.pan_number}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            pan_number: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        PAN File{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.pan_file}
                        </span>
                      </label>
                      <div className="row">
                        <input
                          type="file"
                          className="form-control col-md-10"
                          id="pan_file"
                          onChange={(e) => {
                            var files = e.target.files;
                            if (files && files[0]) {
                              var file_name = e.target.files[0]["name"];
                              const regex = new RegExp(
                                "(.*?)(pdf|png|jpg|jpeg)$"
                              );
                              if (!regex.test(file_name.toLowerCase())) {
                                setFormErrors({
                                  pan_file:
                                    "PAN File is Required (Only PDF & Image file)",
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  pan_file: e.target.files[0],
                                });
                              }
                            } else {
                              setFormErrors({
                                ...formErrors,
                                pan_file:
                                  "PAN File is Required (Only PDF & Image) file allow",
                              });
                            }
                          }}
                        />
                        <div className="col-md-2">
                          {sfdetails.pan_file && (
                            <Link
                              to={"javascript:void(0)"}
                              className="btn-link col-sm-2 text-primary"
                              onClick={() => openSignUrl(sfdetails.pan_file)}
                            >
                              View
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                         Aadhar Number{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.aadhar_number}
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-uppercase"
                        id="aadhar_number"
                        placeholder="Enter Aadhar Number"
                        defaultValue={formData.aadhar_number}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            aadhar_number: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>
                        Aadhar File{" "}
                        <span style={{ color: "red" }}>
                          {formErrors.aadhar_file}
                        </span>
                      </label>
                      <div className="row">
                        <input
                          type="file"
                          className="form-control col-md-10"
                          id="aadhar_file"
                          onChange={(e) => {
                            var files = e.target.files;
                            if (files && files[0]) {
                              var file_name = e.target.files[0]["name"];
                              const regex = new RegExp(
                                "(.*?)(pdf|png|jpg|jpeg)$"
                              );
                              if (!regex.test(file_name.toLowerCase())) {
                                setFormErrors({
                                  aadhar_file:
                                    "Aadhar File is Required (Only PDF & Image file)",
                                });
                              } else {
                                setFormData({
                                  ...formData,
                                  aadhar_file: e.target.files[0],
                                });
                              }
                            } else {
                              setFormErrors({
                                ...formErrors,
                                aadhar_file:
                                  "Aadhar File is Required (Only PDF & Image) file allow",
                              });
                            }
                          }}
                        />
                        <div className="col-md-2">
                          {sfdetails.aadhar_file && (
                            <Link
                              to={"javascript:void(0)"}
                              className="btn-link col-sm-2 text-primary"
                              onClick={() => openSignUrl(sfdetails.aadhar_file)}
                            >
                              View
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-default">
              {/* /.card-header */}
              {ONLY_RM_ASM_ACTION_PERMISSION.includes(roleId) && (
                <div className="card-body">
                  <div className="row">
                    <span style={{ color: "red" }}>{formErrors.mandatory}</span>
                    <br></br>
                    <input
                      type="submit"
                      className="form-control btn btn-primary"
                      value={"Submit"}
                    />
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
