import React from "react";
import Header from "../header/admin/Header";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Dashboard from "../pages/admin/Dashboard";
import Footer from "../header/Footer";
import Appliance from "../pages/admin/master/appliance/Appliance";
import Category from "../pages/admin/master/appliance/Category";
import Capacity from "../pages/admin/master/appliance/Capacity";
import Brand from "../pages/admin/master/appliance/Brand";
import CallTypeGroup from "../pages/admin/master/appliance/CallTypeGroup";
import CallType from "../pages/admin/master/appliance/CallType";
import Model from "../pages/admin/master/appliance/Model";
import ApplianceMapping from "../pages/admin/master/appliance/ApplianceMapping";
import AddUpdatePartner from "../pages/admin/companyPage/AddUpdatePartner";
import Partner from "../pages/admin/companyPage/Partner";
import AddUpdateServiceCenter from "../pages/admin/serviceCenter/AddUpdateServiceCenter";
import ServiceCenterList from "../pages/admin/serviceCenter/ServiceCenterList";
import AddCharges from "../pages/admin/master/callcharge/AddCharges";
import ChargesList from "../pages/admin/master/callcharge/ChargesList";
import SellerChannel from "../pages/SellerChannel";
import CreateBooking from "../pages/admin/booking/CreateBooking";
import PendingList from "../pages/admin/booking/PendingList";
import BookingDetails from "../pages/admin/booking/BookingDetails";
import DATA_NOT_FOUND from "../pages/404";
import AdvanceSearch from "../pages/admin/booking/AdvanceSearch";
import BookingReschedule from "../pages/admin/booking/BookingReschedule";
import CancellationReason from "../pages/admin/master/CancellationReason";
import RescheduleReason from "../pages/admin/master/RescheduleReason";
import BookingCancel from "../pages/admin/booking/BookingCancel";
import UploadPartMaster from "../pages/admin/partmaster/UploadPartMaster";
import PartList from "../pages/admin/partmaster/PartList";
import UnAssignBooking from "../pages/admin/booking/UnAssignBooking";
import MslInward from "../pages/admin/warehouse/MslInward";
import MslInTransit from "../pages/admin/warehouse/MslInTransit";
import MslAckPage from "../pages/admin/warehouse/MslAckPage";
import WarehouseStock from "../pages/admin/warehouse/WarehouseStock";
import MslDisputedList from "../pages/admin/warehouse/MslDisputedList";
import MSLPartList from "../pages/admin/warehouse/MSLPartList";
import MslPartLineItem from "../pages/admin/warehouse/MslPartLineItem";
import { ACCOUNTS_INVOICE_ONLY, ONLY_IM_ACTION_PERMISSION } from "../constant/action_permission";
// import MslOutward from "../pages/admin/warehouse/MslOutward";
import UploadPartBom from "../pages/admin/partmaster/UploadPartBom";
import BomList from "../pages/admin/partmaster/BomList";
// import SpareParts from "../pages/admin/spareParts/SpareParts";
// import ServiceCenterStock from "../pages/admin/warehouse/ServiceCenterStock";
import WarehouseTask from "../pages/admin/warehouse/WarehouseTask";
import UpdateBooking from "../pages/serviceCenter/UpdateBooking";
import CompleteBooking from "../pages/admin/booking/CompleteBooking";
import PendingUnderReview from "../pages/admin/booking/PendingUnderReview";
import CompletedBookingList from "../pages/admin/booking/CompletedBookingList";
import CancelledBookingList from "../pages/admin/booking/CancelledBookingList";
import PartOrderRequest from "../pages/serviceCenter/PartOrderRequest";
import BookingSummary from "../pages/admin/reports/BookingSummary";
import SpareSummary from "../pages/admin/reports/SpareSummary";
import GenerateInvoice from "../pages/admin/invoice/GenerateInvoice";
import CreatemanualInvoice from "../pages/admin/invoice/CreatemanualInvoice";
import CustomerOOWInvoice from "../pages/admin/invoice/CustomerOOWInvoice";
import SpareParts from "../pages/partner/SpareParts";
import InvoiceSummary from "../pages/admin/invoice/InvoiceSummary";
import Addbanktransaction from "../pages/admin/invoice/Addbanktransaction";
import BalanceSummaryforAll from "../pages/admin/invoice/BalanceSummaryforAll";
import ResetPassword from "../pages/ResetPassword";
import CancelledReview from "../pages/admin/booking/CancelledReview";


const AdminRoutes = ({isLoggedIn, roleId}) => {

  const createRoutes = () => (
    <>
      <Route excat path="/dashboard" element={<Dashboard />} />
      <Route excat path="/partner-list" element={<Partner />} />
      <Route excat path="/add-partner/:partnerId" element={<AddUpdatePartner />}  />
      <Route excat path="/add-partner" element={<AddUpdatePartner />}  />
      <Route excat path="/bookingsummary" element={<BookingSummary />} />
      <Route excat path="/bookingsummary/:filter" element={<BookingSummary />} />
      <Route excat path="/sparesummary" element={<SpareSummary />} />
      <Route excat path="/resetpassword" element={<ResetPassword />} />

      {roleId == 1 && (
        <>
          <Route excat path="/appliance-list" element={<Appliance />}  />
          <Route excat path="/category-list" element={<Category />}  />
          <Route excat path="/capacity-list" element={<Capacity />}  />
          <Route excat path="/brand-list" element={<Brand />}  />
          <Route excat path="/calltypegroup-list" element={<CallTypeGroup />}  />
          <Route excat path="/calltype-list" element={<CallType />}  />
          <Route excat path="/model-list" element={<Model />}  />
          <Route excat path="/appliance-mapping-list" element={<ApplianceMapping />} />
          <Route excat path="/addnewcharges" element={<AddCharges />} />
          <Route excat path="/chargeslist" element={<ChargesList/>} />
          <Route excat path="/sellerchannel" element={<SellerChannel/>} />
          <Route excat path="/cancellationreason" element={<CancellationReason />} />
          <Route excat path="/reschedulereason" element={<RescheduleReason />} />
          
        </>
      )}
       {ONLY_IM_ACTION_PERMISSION.includes(roleId) && (
          <>
            <Route excat path="/uploadpartmaster" element={<UploadPartMaster />} />
            <Route excat path="/partlist" element={<PartList />} />
            <Route excat path="/uploadinwardmsl" element ={<MslInward />} />
            {/* <Route excat path="/uploadoutwardmsl" element ={<MslOutward />} /> */}
            <Route excat path="/whintranistmsl" element={<MslInTransit />} />
            <Route excat path="/getmslackpartlist/:msl_pid" element={<MslAckPage />} />
            <Route excat path="/warehousestock" element={<WarehouseStock />} />
            {/* <Route excat path="/scstock" element={<ServiceCenterStock />} /> */}
            <Route excat path="/whmsldisputed" element={<MslDisputedList />} />
            <Route path="/getmslpartlist/:msl_pid" element={<MSLPartList />} />
            <Route path="/getpartitem/:purchase_item_id/:part_number" element={<MslPartLineItem />} />
            <Route path="/uploadbom" element={<UploadPartBom />} />
            <Route path="/bomlist" element={<BomList />} />
            <Route path="/warehousetask" element={<WarehouseTask />} />
            <Route excat path="/updatebooking/:bid/:isPartOrder/:spare_id" element={<UpdateBooking />} />
            <Route excat path="/requestpartorder/:bid/:spare_id" element={<PartOrderRequest />} />
          </>
       )}

       {ACCOUNTS_INVOICE_ONLY.includes(roleId) && (
          <>
            <Route excat path="/generateinvoice" element={<GenerateInvoice />} />
            <Route excat path="/createmanualinvoice" element={<CreatemanualInvoice />} />
            <Route excat path="/invoicesummary/:entype" element={<InvoiceSummary />} />
            <Route excat path="/addbanktransaction" element={<Addbanktransaction />} />
            <Route excat path="/getbalancesummary" element={<BalanceSummaryforAll />} />
          </>
       )}

      <Route excat path="/customeroowinvoice" element={<CustomerOOWInvoice />} />
      <Route excat path="/addpartner" element={<AddUpdatePartner />} />
      <Route excat path="/updatepartner/:partnerId" element={<AddUpdatePartner />} />
      <Route excat path="/addservicecenter" element={<AddUpdateServiceCenter />} />
      <Route excat path="/updateservicecenter/:id" element={<AddUpdateServiceCenter />} />
      <Route excat path="/viewservicecenter" element={<ServiceCenterList />} />
      <Route excat path="/createbooking" element={<CreateBooking/>} />
      <Route excat path="/pendingbooking" element={<PendingList/>} />
      <Route excat path="/getbookingdetails" element={<BookingDetails />} />
      <Route excat path="/updatebooking/:bid" element={<CreateBooking />} />
      <Route excat path="/updatebooking/:bid/:isPartOrder" element={<UpdateBooking />} />
      <Route excat path="/completebooking/:bid" element={<CompleteBooking />} />
      <Route excat path="/pendingunderreview" element={<PendingUnderReview />} />
      <Route excat path="/cancelledreview" element={<CancelledReview />} />
      <Route excat path="/search" element={<AdvanceSearch />} />
      <Route excat path="/reschedule/:id" element={<BookingReschedule />} />
      <Route excat path="/cancel/:id" element={<BookingCancel />} />
      <Route excat path="/assignsc" element ={<UnAssignBooking />} />
      <Route excat path="/partnerwhtask" element={<SpareParts />} />
      
      <Route excat path="/getcompletedlist" element={<CompletedBookingList />} />
      <Route excat path="/getcancelledlist" element={<CancelledBookingList />} />

      

      <Route excat path="/404" element={<DATA_NOT_FOUND />} />
    </>
  );
  return (
    <div>
      <Header />
      <Routes>
        {createRoutes()}
        <Route
          excat
          path="*"
          element={<Navigate to={isLoggedIn ? "/dashboard" : "/"} />}
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default AdminRoutes;