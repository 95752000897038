import React, { useState, useEffect } from "react";
import MainBody from "../../../component/MainBody";
import httpRequest from "../../../utils/Http";
import Select from "react-select";
import GenerateChallanSendToSFTab from "./tabs/GenerateChallanSendToSFTab";
import DispatchChallanToSFTab from "./tabs/DispatchChallanToSFTab";
import TobeReceiveReverseParts from "./tabs/TobeReceiveReverseParts";
import ReceivedReverseParts from "./tabs/ReceivedReverseParts";
import DispatchReverseChallan from "./tabs/DispatchReverseChallan";
import PartsOnApproval from "./tabs/PartsOnApproval";

const WarehouseTask = () => {
  const [breadcrumbSubTitle, setBreadcrumbSubTitle] = useState(
    "Parts On Approval"
  );
  const [warehouselist, setWarehouselist] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({ warehouse_id: "" });
  const [reloadList, setReload] = useState(false)
  

  useEffect(() => {
    (async () => {
      const warehouse = await httpRequest("getcwhdropdownlist", {}, "GET");
      if (warehouse.status === true && warehouse.data) {
        setWarehouselist(warehouse.data);
        if (warehouse.data.length === 1) {
          setFormData({
            ...formData,
            warehouse_id: warehouse.data[0]["value"],
          });

          setReload(true)
        }
      }
    })();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <MainBody
        breadcrumbTitle="Warehouse Task"
        breadcrumbSubTitle={breadcrumbSubTitle}
        navButton=""
        title={
          <div className="col-md-4">
            <div className="form-group">
              <label>
                Central Warehouse *{" "}
                <span style={{ color: "red" }}>{formErrors.warehouse_id}</span>
              </label>
              <Select
                name="warehouse_id"
                value={
                  warehouselist.find(
                    (option) => option.value === formData.warehouse_id
                  ) || null
                }
                options={warehouselist}
                onChange={(val) => {
                  setFormData({
                    ...formData,
                    warehouse_id: val.value,
                  });
                  setReload(true)
                }}
              />
            </div>
          </div>
        }
        component={
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header p-2">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            href="#partsonapproval"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle(
                                "Parts On Approval"
                              );
                            }}
                          >
                            Parts On Approval
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#generatechallan"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle(
                                "Generate Challan Send To Sf"
                              );
                            }}
                          >
                            Generate Challan Send To Sf
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#dispatchchallan"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Dispatch Challan To Sf");
                            }}
                          >
                            Dispatch Challan To SF
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#reversetobereceive"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Reverse Challan To Be Received");
                            }}
                          >
                            Reverse Challan To Be Received
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#reversechallangenerate"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Generate Reverse Challan/Invoice Send to Partner");
                            }}
                          >
                            Generate Reverse Challan/ Invoice Send to Partner
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#dispatchReverse"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Dispatch Reverse Challan/Invoice Send To Partner");
                            }}
                          >
                            Dispatch Reverse Challan/Invoice Send To Partner
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="tab-content">
                      <div className="active tab-pane" id="partsonapproval">
                          {formData.warehouse_id ? (<>
                            <PartsOnApproval
                            warehouse_id={formData.warehouse_id}
                            reloadList={reloadList}
                            setReload={setReload}
                            setFormErrors={setFormErrors}
                            formErrors={formErrors}
                          />
                          </>): <>
                            Select Warehouse
                          </>}
                        </div>

                        <div className="tab-pane" id="generatechallan">
                          {formData.warehouse_id ? (<>
                            <GenerateChallanSendToSFTab
                            warehouse_id={formData.warehouse_id}
                            reloadList={reloadList}
                            setReload={setReload}
                            setFormErrors={setFormErrors}
                            formErrors={formErrors}
                          />
                          </>): <>
                            Select Warehouse
                          </>}
                        </div>

                        <div className="tab-pane" id="dispatchchallan">
                        {formData.warehouse_id ? (<>
                        <DispatchChallanToSFTab
                            warehouse_id={formData.warehouse_id}
                            reloadList={reloadList}
                            setReload={setReload}
                            setFormErrors={setFormErrors}
                            formErrors={formErrors}
                          />
                          </>): <>
                            Select Warehouse
                          </>}
                          
                        </div>
                        <div className="tab-pane" id="reversetobereceive">
                        {formData.warehouse_id ? (<>
                        <TobeReceiveReverseParts
                            warehouse_id={formData.warehouse_id}
                            reloadList={reloadList}
                            setReload={setReload}
                            setFormErrors={setFormErrors}
                            formErrors={formErrors}
                          />
                          </>): <>
                            Select Warehouse
                          </>}
                          
                        </div>
                        <div className="tab-pane" id="reversechallangenerate">
                        {formData.warehouse_id ? (<>
                        <ReceivedReverseParts
                            warehouse_id={formData.warehouse_id}
                            reloadList={reloadList}
                            setReload={setReload}
                            setFormErrors={setFormErrors}
                            formErrors={formErrors}
                          />
                          </>): <>
                            Select Warehouse
                          </>}
                          
                        </div>
                        <div className="tab-pane" id="dispatchReverse">
                        {formData.warehouse_id ? (<>
                        <DispatchReverseChallan
                            warehouse_id={formData.warehouse_id}
                            reloadList={reloadList}
                            setReload={setReload}
                            setFormErrors={setFormErrors}
                            formErrors={formErrors}
                          />
                          </>): <>
                            Select Warehouse
                          </>}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      />
    </div>
  );
};

export default WarehouseTask;
