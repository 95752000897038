import React, { useState } from "react";
import DispatchParts from "./DispatchParts";
import MainBody from "../../component/MainBody";
import TobeReceiveReverseParts from "./TobeReceiveReverseParts";
import ReceivedReverseParts from "./ReceivedReverseParts";

const SpareParts = () => {
  const [breadcrumbSubTitle, setBreadcrumbSubTitle] = useState(
    "Dispatch Parts to SF"
  );
  const [formErrors, setFormErrors] = useState({});
  const [reloadList, setReload] = useState(false);

  return (
    <div>
      <MainBody
        breadcrumbTitle="Warehouse Task"
        breadcrumbSubTitle={breadcrumbSubTitle}
        navButton=""
        title={""}
        component={
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header p-2">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <a
                            className=" active nav-link"
                            href="#dispatchchallan"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Dispatch Parts to SF");
                            }}
                          >
                            Dispatch Parts to SF
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#receive_reverse"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle(
                                "Reverse Part To Be Received"
                              );
                            }}
                          >
                            Reverse Part To Be Received
                          </a>
                        </li>
                        {/* <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#tobewh"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("To Be Received Part Shipped By WH");
                            }}
                          >
                            To Be Received Part Shipped By WH
                          </a>
                        </li> */}
                        <li className="nav-item">
                          <a
                            className=" nav-link"
                            href="#received_reverse"
                            data-toggle="tab"
                            onClick={() => {
                              setBreadcrumbSubTitle("Received Reverse Parts");
                            }}
                          >
                            Received Reverse Parts
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="tab-content">
                        <div className="active tab-pane" id="dispatchchallan">
                          <DispatchParts
                            reloadList={reloadList}
                            setReload={setReload}
                            setFormErrors={setFormErrors}
                            formErrors={formErrors}
                          />
                        </div>
                        <div className="tab-pane" id="receive_reverse">
                          <TobeReceiveReverseParts 
                           reloadList={reloadList}
                           setReload={setReload}
                          />
                        </div>
                        {/* <div className="tab-pane" id="tobewh">
                          <TobeReceiveReverseParts 
                           reloadList={reloadList}
                           setReload={setReload}
                          />
                        </div> */}
                        <div className="tab-pane" id="received_reverse">
                          <ReceivedReverseParts 
                           reloadList={reloadList}
                           setReload={setReload}
                           />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      />
    </div>
  );
};

export default SpareParts;
